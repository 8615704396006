@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700);
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #4A4A4A;
  overflow-x: hidden; }

body {
  height: auto;
  min-height: 550px;
  overflow: hidden;
  margin: 0; }

a {
  text-decoration: none; }

.search-form {
  padding: 10px; }
  .search-form label {
    margin-right: 5px; }
  .search-form .marginLeft10 {
    margin-left: 10px; }

.clear {
  clear: both; }

a:link, a:visited {
  color: #F7931D; }

.hasLoader {
  min-height: 600px;
  position: relative;
  content: '';
  background-image: url(/static/media/loading.5fe4039b.gif);
  background-repeat: no-repeat;
  background-position: center; }

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff; }

.custom-ui > h1 {
  margin-top: 0; }

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px; }

/* Colors */
/* background */
/*==============mixin==============*/
/*==================Responsive===================*/
@media screen and (min-width: 0) and (max-width: 768px) {
  .nav-md .container.body .right_col main {
    width: auto !important; }
  .QRScanner .Wrapper .Container {
    min-width: 300px;
    max-width: 300px; }
  .CropAvatar .ImageContent, .CropAvatar .FileChooser, .CropAvatar .AvatarPreview {
    min-width: 300px;
    max-width: 300px;
    overflow: hidden; }
  .CropAvatar .Controls {
    text-align: center; }
    .CropAvatar .Controls > .btn-group {
      margin: 5px; } }

@media screen and (max-width: 320px) {
  .QRScanner .Wrapper .Container {
    min-width: 275px;
    max-width: 275px; }
  .CropAvatar .ImageContent, .CropAvatar .FileChooser, .CropAvatar .AvatarPreview {
    min-width: 275px;
    max-width: 275px; } }

.Logo {
  position: relative; }
  .Logo .LogoProtector {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer; }
  .Logo.center {
    text-align: center; }
    .Logo.center .LogoProtector {
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }

.SiteLogo {
  background-color: #FFFFFF;
  padding: 12px;
  overflow: hidden; }

.Image {
  max-width: 100%; }

.CropAvatar .Wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1000; }
  .CropAvatar .Wrapper .close-popup {
    position: absolute;
    top: 5px;
    right: 0; }
  .CropAvatar .Wrapper .previous-step {
    position: absolute;
    top: 5px;
    left: 5px; }
  .CropAvatar .Wrapper .Header {
    margin-bottom: 10px; }
  .CropAvatar .Wrapper .DragDrop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    border: 2px dotted #00bfff;
    height: 250px; }
    .CropAvatar .Wrapper .DragDrop .fa-plus {
      font-size: 60px; }
  .CropAvatar .Wrapper .ButtonControls {
    text-align: right;
    margin-top: 10px; }
  .CropAvatar .Wrapper .Previewer {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .CropAvatar .Wrapper .Previewer img {
      height: 100%;
      border: 1px solid black; }
  .CropAvatar .Wrapper .AvatarPreviewBox {
    display: flex;
    justify-content: center;
    align-items: center; }
    .CropAvatar .Wrapper .AvatarPreviewBox img {
      border: 1px solid black; }

.CropAvatar .OverLay:after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5); }

.CropAvatar .ImageContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .CropAvatar .ImageContent .Cropper {
    max-height: 500px; }
  .CropAvatar .ImageContent .Controls {
    margin-top: 10px; }

.Spinner:after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1011;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2); }

.Spinner .Animation {
  top: 50%;
  left: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 9999; }

.QRScanner .Wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1000; }
  .QRScanner .Wrapper .close-popup {
    position: absolute;
    top: 5px;
    right: 0; }
  .QRScanner .Wrapper .Container {
    padding-top: 15px; }
  .QRScanner .Wrapper .Scanner {
    width: 100%;
    max-width: 500px;
    margin: 0 auto; }
    .QRScanner .Wrapper .Scanner .Tip {
      padding-top: 10px;
      margin-bottom: 5px; }
  .QRScanner .Wrapper .Heading {
    text-align: center;
    margin: 5px; }

.QRScanner .OverLay:after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5); }

.FormFooter .FormGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  .FormFooter .FormGroup .ImageContainer {
    position: relative;
    flex-basis: 60px;
    height: 50px; }
    .FormFooter .FormGroup .ImageContainer img {
      border: 1px solid #F7931D; }
    .FormFooter .FormGroup .ImageContainer .BigImage {
      position: absolute;
      left: 52px;
      bottom: 0; }
  .FormFooter .FormGroup .Address {
    flex: 1 1; }

.LichSuKhamBenh .ParentGroup {
  display: flex;
  align-items: center;
  color: darkred;
  font-size: 20px;
  padding: 10px;
  background-color: gainsboro;
  text-transform: uppercase; }
  .LichSuKhamBenh .ParentGroup .ParentGroupIcon {
    margin-right: 5px; }

.XemBenhSu .ParentGroup {
  display: flex;
  align-items: center;
  color: darkred;
  font-size: 20px;
  padding: 10px;
  background-color: gainsboro;
  text-transform: uppercase; }
  .XemBenhSu .ParentGroup .ParentGroupIcon {
    margin-right: 5px; }

.InputErrorDisplayer {
  text-align: left;
  margin-top: 5px;
  color: red; }

