@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700');

body,html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #4A4A4A;
  overflow-x: hidden;
  // background: #dbdbdb;
}
body {
    height: auto;
    min-height: 550px;
    overflow: hidden;
    margin: 0;
}
a {
  text-decoration: none;
}
.search-form{
    padding: 10px;
    label {
        margin-right: 5px;
    }
    .marginLeft10 {
        margin-left: 10px;
    }
}
.clear {
    clear: both;
}

a:link, a:visited {
	color: #F7931D;
}


.hasLoader {
  min-height: 600px;
  position: relative;
  content: '';
  background-image: url("../images/loading.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.nav-md .container.body .right_col {
  main {
    // width: 100%!important;
  }
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h1 {
  margin-top: 0;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}
