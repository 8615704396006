.Spinner{
  &:after{
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1011;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .Animation{
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
}
