.Logo{
  position: relative;

  .LogoProtector{
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
  }

  &.center{
    text-align: center;

    .LogoProtector{
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
