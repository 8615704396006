.LichSuKhamBenh{
  .ParentGroup{
    display: flex;
    align-items: center;
    color: darkred;
    font-size: 20px;
    padding: 10px;
    background-color: gainsboro;
    text-transform: uppercase;

    .ParentGroupIcon{
      margin-right: 5px;
    }
  }
}